import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Paper, Stack, Box, Button } from '@mui/material';
import React, { useState } from 'react';

import { SmaverseTag } from '@app/components/Product/SmaverseTag';
import { useDeviceType } from '@app/hooks/useBrowserHooks';

interface CarouselItemProps {
  displayMode: 'largeImage' | 'carousel';
  image: string;
}

export function CarouselItem({ displayMode, image }: CarouselItemProps) {
  const { isMobile } = useDeviceType();

  return (
    <Paper>
      <img
        src={image}
        alt="carousel item"
        style={{
          height: isMobile ? 'auto' : '240px',
          objectFit: 'cover',
          width: isMobile
            ? displayMode === 'carousel'
              ? '280px'
              : '30vw'
            : '360px',
        }}
      />
    </Paper>
  );
}

interface ImageCarouselProps {
  carouselMaxWidth?: string;
  carouselMinWidth?: string;
  carouselWidth?: string;
  displayMode: 'largeImage' | 'carousel';
  images: string[];
  isSmaverse?: boolean;
}

export function ImageCarousel({
  images,
  displayMode,
  carouselWidth,
  carouselMinWidth,
  carouselMaxWidth,
  isSmaverse,
}: ImageCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { isMobile } = useDeviceType();

  const handlePrev = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {displayMode === 'largeImage' && (
        <Box sx={{ mb: 3, position: 'relative' }}>
          <img
            src={images[currentIndex]}
            alt="selected item"
            style={{
              height: isMobile ? '225px' : '550px',
              objectFit: 'cover',
              width: isMobile ? '100%' : '980px',
            }}
          />
          {isSmaverse && (
            <SmaverseTag
              sx={{
                left: {
                  md: '12px',
                  xs: '4px',
                },
                top: {
                  md: '12px',
                  xs: '4px',
                },
              }}
            />
          )}
        </Box>
      )}

      {(displayMode === 'carousel' || displayMode === 'largeImage') && (
        <Stack
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
          direction="row"
        >
          <Box
            sx={{
              maxWidth: carouselMaxWidth,
              minWidth: carouselMinWidth,
              overflow: 'hidden',
              position: 'relative',
              width: carouselWidth,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                transform: isMobile
                  ? displayMode === 'carousel'
                    ? `translateX(-${currentIndex * 280}px)`
                    : `translateX(-${currentIndex * 30}vw)`
                  : `translateX(-${currentIndex * 360}px)`,
                transition: 'transform 0.5s ease-in-out',
              }}
            >
              {images.map((image, index) => (
                <Box sx={{ mx: 0.5 }} key={index}>
                  <CarouselItem image={image} displayMode={displayMode} />
                </Box>
              ))}
            </Box>
            <Button
              onClick={handlePrev}
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                },
                backgroundColor: 'white',
                borderRadius: '50%',
                left: 8,
                minHeight: '40px',
                minWidth: '40px',
                padding: 0,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1,
              }}
            >
              <ArrowBackIcon />
            </Button>
            <Button
              onClick={handleNext}
              sx={{
                '&:hover': {
                  backgroundColor: 'white',
                },
                backgroundColor: 'white',
                borderRadius: '50%',
                minHeight: '40px',
                minWidth: '40px',
                padding: 0,
                position: 'absolute',
                right: 8,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1,
              }}
            >
              <ArrowForwardIcon />
            </Button>
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
