import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getOrganization } from '@app/adapter/organization-service';
import { OrganizationDetail } from '@app/components/Company/OrganizationDetail';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { LoadingSpinner } from '@app/components/Shared/LoadingSpinner';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { ORGANIZATION_STRUCTURE_TYPE } from '@app/static/constants';
import { Organization } from '@app/types/organization';
import { isError } from '@app/utils/error';

export function Preview(): ReactElement {
  const { id } = useParams();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const setSnackbar = useSetSnackbar();

  const fetchOrganization = useCallback(async () => {
    if (!id) return;
    try {
      const { data } = await getOrganization(id, {
        expand: `${ORGANIZATION_STRUCTURE_TYPE.CHILDREN},mainImage,strengthImage`,
        isPreview: true,
      });
      setOrganization(data);
    } catch (error) {
      if (isError(error)) {
        setSnackbar(true, '企業情報の取得に失敗しました');
        console.error(error.message);
      }
    }
  }, [id, setSnackbar]);

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      await fetchOrganization();
      setIsLoading(false);
    };
    void init();
  }, [fetchOrganization]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        !!organization && (
          <>
            <HeadBlock isNoIndex />
            <OrganizationDetail organization={organization} />
          </>
        )
      )}
    </>
  );
}
