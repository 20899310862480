import { EmptyKey } from '@app/types/common';
import { Organization } from '@app/types/organization';

/**
 * Category
 */
export interface Category {
  children: Category[];
  description: string;
  icon?: {
    type: string;
    value: string;
  };
  id: string;
  name: string;
  numberOfProducts: number;
  parent: null | {
    uri: string;
  };
  status: CategoryStatusKey;
}

export interface CategoryWithCount extends Category {
  children: (Category & { count: number })[];
}

export interface LocationWithCount extends ProductLocation {
  count: number;
}

export interface CategoryProductCount {
  count: number;
  id: string;
}

export interface LocationProductCount {
  count: number;
  id: string;
}

export type CategoryState = {
  id: string;
  name: string;
};

export interface GeoCoordinatesField {
  coordinates?: number[];
  type?: string;
}

export const CategoryType = {
  EXHIBITION: 'exhibition',
  PROPERTY: 'property',
} as const;
export type CategoryTypeKey = (typeof CategoryType)[keyof typeof CategoryType];

export const CategoryName = {
  EXHIBITION: '住宅展示場',
  PROPERTY: '分譲',
} as const;
export type CategoryNameKey = (typeof CategoryName)[keyof typeof CategoryName];

export const CategoryStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
} as const;
export type CategoryStatusKey =
  (typeof CategoryStatus)[keyof typeof CategoryStatus];

export const CategoryIconType = {
  MATERIAL_ICON: 'materialIcon',
  MATERIAL_SYMBOLS: 'materialSymbols',
} as const;
export type CategoryIconTypeKey =
  (typeof CategoryIconType)[keyof typeof CategoryIconType];

/**
 * Product
 */
export interface Product {
  additionalInformation: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  attributes: Attribute[];
  category?: { id: string; name: string };
  categoryId: string;
  createdAt: string;
  customFields: ProductCustomFields;
  description: string;
  dimensions: string;
  favorite: boolean;
  features: ProductFeature[];
  geoCoordinatesField: GeoCoordinatesField;
  id: string;
  imageIds?: string[];
  images: Attachment[];
  isPromoted?: boolean;
  locationIds: string[];
  locations: ProductLocation[];
  name: string;
  organization?: Organization | string;
  organizationId: string;
  publication: ProductPublication;
  tags: string[];
  updatedAt: string;
  variantNote: string;
  variants: ProductVariant[];
}

export interface ProductCustomFields {
  access?: string;
  addressLine3HiddenFlag?: boolean;
  addressLine4?: string;
  areaRatio?: string;
  breakTime?: string;
  breakTimeNoParticular?: boolean;
  buildingAreaRangeType?: ProductRangeTypeKey;
  buildingAreaType?: ProductLandAreaTypeKey;
  buildingConfirmationNumber?: string;
  buildingPermitReason?: string;
  buildingPermitReasonType?: number;
  buildingStatus?: BuildingStatus;
  buildingSystem?: string;
  calculatedHourlyPay?: number;
  closingTime?: string;
  commutingCost?: number;
  companyAddress?: string;
  companyName?: string;
  completionDate?: string;
  completionDateType?: ProductCompletionDateTypeKey;
  completionSeason?: ProductCompletionSeasonKey;
  conditionsTransaction?: string;
  conditionsTransactionNote?: string;
  constructionCompany?: string;
  contactAdditional?: string;
  contactAddress?: string;
  contactPhoneNumber?: string;
  count?: number;
  day?: string;
  deliveryDate?: string;
  deliveryDateType?: ProductDeliveryDateTypeKey;
  deliveryNote?: string;
  deliverySeason?: ProductDeliverySeasonKey;
  endTime?: string;
  eventAction?: ProductEventActionKey | '';
  eventContent?: string;
  eventDateList?: string[];
  eventDateSince?: string;
  eventDateType?: ProductEventDateTypeKey | '';
  eventDateUntil?: string;
  eventName?: ProductEventNameKey | '';
  eventTimeSince?: string;
  eventTimeUntil?: string;
  facility?: string;
  facilityCost?: string;
  floorPlanRangeType?: ProductRangeTypeKey;
  floorPlanRooms?: string[];
  floorPlanTypeAdditional?: string;
  floorPlanTypeMax?: string;
  floorPlanTypeMin?: string;
  groupName?: string;
  landAreaRangeType?: ProductRangeTypeKey;
  landAreaType?: ProductLandAreaTypeKey;
  landCategory?: string;
  landLeaseholdCost?: string;
  landLeaseholdDuration?: string;
  landLeaseholdRent?: string;
  landRightType?: string;
  legalLimitations?: string[];
  legalOtherLimitationNote?: string;
  legalOtherLimitationType?: ProductLegalOtherLimitationTypeKey | '';
  legalOtherLimitations?: string[];
  licenseNumber?: string;
  lotteryDate?: string;
  lotteryDateSeason?: ProductLotteryDateSeasonKey;
  mainImages?: [];
  maxBuildingArea?: number;
  maxLandArea?: number;
  maxPrice?: number;
  maxRoadWidth?: string;
  minBuildingArea?: number;
  minLandArea?: number;
  minPrice?: number;
  minRoadWidth?: string;
  mostPriceRangeAdditional?: string;
  mostPriceRangeCount?: string;
  mostPriceRanges?: string[];
  openingTime?: string;
  orderConditions?: string;
  otherCost?: string;
  overWorkConditions?: string[];
  previewAdComment?: string;
  priceAdditional?: string;
  priceNote?: string;
  priceRangeType?: ProductRangeTypeKey;
  propertyCategory?: PropertyCategory;
  reformExterior?: ProductReformExteriorKey;
  reformInterior?: ProductReformInteriorKey;
  regularHoliday?: string;
  regularHolidayAdditional?: string;
  repeatDetail?: string;
  repeatWeek?: number[];
  roadNote?: string;
  roadPainting?: string;
  roadWidthRangeType?: string;
  salesDateSeason?: ProductSalesDateSeasonKey;
  salesEndDate?: string;
  salesHouseCount?: number;
  salesScheduleComment?: string;
  salesStartDate?: string;
  salesStatus?: ProductSalesStatusKey;
  salesType?: ProductSalesTypeKey;
  selection?: string;
  startTime?: string;
  strengths?: string[];
  strengthsDescription?: string;
  strengthsTitle?: string;
  totalHouseCount?: number;
  transactionExpirationDate?: string;
  transportMainLine?: string;
  transportMainMean?: TransportMeansKey;
  transportMainNearest?: string;
  transportMainNote?: string;
  transportMainTimeMax?: number;
  transportMainTimeMin?: number;
  transportSub1Line?: string;
  transportSub1Mean?: TransportMeansKey;
  transportSub1Nearest?: string;
  transportSub1Note?: string;
  transportSub1TimeMax?: number;
  transportSub1TimeMin?: number;
  transportSub2Line?: string;
  transportSub2Mean?: TransportMeansKey;
  transportSub2Nearest?: string;
  transportSub2Note?: string;
  transportSub2TimeMax?: number;
  transportSub2TimeMin?: number;
  tsuboNotationFlag?: boolean;
  useDistrict?: [];
  workAddress1?: string;
  workAddress2?: string;
  workPostalCode?: string;
  workPublicHoliday?: boolean;
}

export interface ProductPublication {
  publishedAt?: string;
  publishedBy?: string;
  since?: string;
  status: ProductPublicationStatusKey;
  until?: string;
}

export interface SearchResultProducts {
  data: Product[];
  nextLink: string;
  scrollY?: number;
  total: number;
}

export interface SearchCondition {
  access?: string;
  attributeIds?: string[];
  categoryId?: string | null;
  floorPlanRooms?: number[];
  locationIds?: string[];
  maxBuildingArea?: string;
  maxLandArea?: string;
  maxPrice?: string;
  minBuildingArea?: string;
  minLandArea?: string;
  minPrice?: string;
  orderBy?: string;
  organizationId?: string[];
  variant?: {
    max?: string;
    min?: string;
    type?: string;
  };
}

export interface ProductPrice {
  amount: number;
  taxable: boolean;
}

export interface ProductImage {
  alt?: string;
  createdAt?: string;
  id?: string;
  productId?: string;
  src: string;
  updatedAt?: string;
  variantIds?: string[];
}

/**
 * Product Variant
 */
export interface ProductVariant {
  customFields?: ProductVariantCustomFields;
  description?: string;
  id: string;
  images?: Attachment[];
  price: VariantPrice;
  sku?: ProductVariantSkuKey;
  title: string;
}

export interface VariantPrice {
  amount: number;
  currency: string;
  taxIncluded: boolean;
}

export interface ProductVariantCustomFields {
  buildingArea?: string;
  category?: string;
  comment?: string;
  exclusiveArea?: string;
  floorPlan?: string;
  landArea?: string;
  price?: string;
}

export const ProductVariantSku = {
  // 周辺環境
  AROUND: 'around',
  // 設備
  EQUIPMENT: 'equipment',
  // 外観
  EXTERIOR: 'exterior',
  // 間取り
  FLOOR_PLAN: 'floorPlan',
  // 内観
  INTERIOR: 'interior',
  // 区画
  PLOT_MAP: 'plotMap',
} as const;
export type ProductVariantSkuKey =
  (typeof ProductVariantSku)[keyof typeof ProductVariantSku];

/**
 * attachment
 */
export interface Attachment {
  id: string;
  isPublic: boolean;
  ownerId: string;
  ownerType: string;
  type: string;
  url: string;
}

export interface ProductFeature {
  name: string;
  value: string;
}

export const ProductType = {
  REGULAR_PART_TIME: 'regularPartTime',
  SPOT: 'spot',
} as const;
export type ProductTypeKey = (typeof ProductType)[keyof typeof ProductType];

export const ProductPublicationStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
} as const;
export type ProductPublicationStatusKey =
  (typeof ProductPublicationStatus)[keyof typeof ProductPublicationStatus];

export const ProductCommuting = {
  FIXED_FEE: 'FixedFee',
  INCLUDE: 'Include',
  NO_FEE: 'NoFee',
  NO_UPPER_LIMIT: 'NoUpperLimit',
  UPPER_LIMIT: 'UpperLimit',
} as const;
export type ProductCommutingKey =
  (typeof ProductCommuting)[keyof typeof ProductCommuting];
export const ProductCommutingLabel = (
  key: ProductCommutingKey | ''
): string => {
  switch (key) {
    case ProductCommuting.FIXED_FEE:
      return '別途支給(定額)';
    case ProductCommuting.INCLUDE:
      return '交通費込み(所定金額)';
    case ProductCommuting.NO_FEE:
      return 'なし';
    case ProductCommuting.NO_UPPER_LIMIT:
      return '別途支給(上限なし)';
    case ProductCommuting.UPPER_LIMIT:
      return '別途支給(上限金額)';
  }
  return '';
};

/**
 * Attribute
 */
export interface Attribute {
  attributeId?: string;
  categoryIds: string[];
  children?: Attribute[];
  customFields?: AttributeCustomFields;
  groupName: string;
  id: string;
  items: AttributeItem[];
  name: string;
  order: number;
  type: string;
}

export interface AttributeCustomFields {
  icon?: {
    type: string;
    value: string;
  };
  keywords?: string;
}

export interface AttributeItem {
  groupName: string;
  key: string;
  value: string;
}
// Todo: Remove unused code: JOB_TYPE, CLINICAL_DEPARTMENT: 現状未使用のため削除が必要
export const AttributeGroupName = {
  CLINICAL_DEPARTMENT: 'clinicalDepartment',
  JOB_TYPE: 'jobType',
  SMAVERSE: 'smaverse',
} as const;
export type AttributeGroupNameKey =
  (typeof AttributeGroupName)[keyof typeof AttributeGroupName];

/**
 * Location
 */
export interface City {
  city: string;
  cityCode: string;
}

export interface ProductLocation {
  children?: ProductLocation[];
  code: string | null;
  createdAt?: string;
  id: string;
  name: string;
  numberOfProducts?: number;
  type: ProductLocationTypeKey;
  updatedAt?: string;
}

export const ProductLocationType = {
  CITY: 'city',
  PREFECTURE: 'prefecture',
  REGION: 'region',
} as const;
export type ProductLocationTypeKey =
  (typeof ProductLocationType)[keyof typeof ProductLocationType];

export const ProductSalesType = {
  FCFS: { dateLabel: '先着申込受付開始日', key: 'fcfs', label: '先着順' },
  LOTTERY: { dateLabel: '登録受付日', key: 'lottery', label: '登録抽選' },
  PENDING: { dateLabel: '販売開始日', key: 'pending', label: '未定' },
} as const;
export type ProductSalesTypeKey =
  (typeof ProductSalesType)[keyof typeof ProductSalesType]['key'];

export const ProductLandAreaType = {
  REGISTRY: { key: 'registry', label: '登記' },
  SURVEY: { key: 'survey', label: '実測' },
} as const;
export type ProductLandAreaTypeKey =
  (typeof ProductLandAreaType)[keyof typeof ProductLandAreaType]['key'];

export const ProductCompletionDateType = {
  COMPLETED: { key: 'completed', label: '完成済み' },
  COMPLETION_PLAN: { key: 'completionPlan', label: '完成予定' },
  CONTRACTED: { key: 'contracted', label: '契約後' },
} as const;
export type ProductCompletionDateTypeKey =
  (typeof ProductCompletionDateType)[keyof typeof ProductCompletionDateType]['key'];

export const ProductCompletionSeason = {
  EARLY: { key: 'early', label: '初旬' },
  END: { key: 'end', label: '末' },
  LATE: { key: 'late', label: '下旬' },
  MIDDLE: { key: 'middle', label: '中旬' },
} as const;
export type ProductCompletionSeasonKey =
  (typeof ProductCompletionSeason)[keyof typeof ProductCompletionSeason]['key'];

export const ProductDeliveryDateType = {
  CONSULTATION: { key: 'consultation', label: '相談' },
  CONTRACTED: { key: 'contracted', label: '契約後' },
  IMMEDIATE: { key: 'immediate', label: '即引渡可' },
  SPECIFIC: { key: 'specific', label: '指定有' },
} as const;
export type ProductDeliveryDateTypeKey =
  (typeof ProductDeliveryDateType)[keyof typeof ProductDeliveryDateType]['key'];

export const ProductDeliverySeason = {
  EARLY: { key: 'early', label: '初旬' },
  END: { key: 'end', label: '末' },
  LATE: { key: 'late', label: '下旬' },
  MIDDLE: { key: 'middle', label: '中旬' },
} as const;
export type ProductDeliverySeasonKey =
  (typeof ProductDeliverySeason)[keyof typeof ProductDeliverySeason]['key'];

export const ProductReformInterior = {
  COMPLETION_PLAN: { key: 'completionPlan', label: '完了予定' },
  CONTRACTED: { key: 'completed', label: '済' },
  NONE: { key: 'none', label: '無' },
} as const;
export type ProductReformInteriorKey =
  (typeof ProductReformInterior)[keyof typeof ProductReformInterior]['key'];

export const ProductReformExterior = {
  COMPLETION_PLAN: { key: 'completionPlan', label: '完了予定' },
  CONTRACTED: { key: 'completed', label: '済' },
  NONE: { key: 'none', label: '無' },
} as const;
export type ProductReformExteriorKey =
  (typeof ProductReformInterior)[keyof typeof ProductReformInterior]['key'];

export const ProductEventTitle = {
  LOCAL_GUIDE: 'localGuide', // 現地案内会
  LOCAL_SALE: 'localSale', // 現地販売会
  LOCAL_TOUR: 'localTour', // 現地見学会
  MODEL_HOUSE: 'modelHouse', // モデルハウス
  MODEL_ROOM: 'modelRoom', // モデルルーム
  OPEN_HOUSE: 'openHouse', // オープンハウス
  OPEN_ROOM: 'openRoom', // オープンルーム
} as const;
export type ProductEventTitleKey =
  | (typeof ProductEventTitle)[keyof typeof ProductEventTitle]
  | EmptyKey;

export const ProductLandRightType = {
  FIXED_GROUND: 'fixedGround', // 定期地上
  FIXED_LEASE: 'fixedLease', // 定期賃借
  OLD_LAW_GROUND: 'oldLawGround', // 旧法地上
  OLD_LAW_LEASE: 'oldLawLease', // 旧法賃借
  OWNERSHIP: 'ownership', // 所有権
  REGULAR_GROUND: 'regularGround', // 普通地上
  REGULAR_LEASE: 'regularLease', // 普通賃借
} as const;
export type ProductLandRightTypeKey =
  | (typeof ProductLandRightType)[keyof typeof ProductLandRightType]
  | EmptyKey;

export const ProductRangeType = {
  BETWEEN: 'between',
  SEPARATE: 'separate',
} as const;
export type ProductRangeTypeKey =
  | (typeof ProductRangeType)[keyof typeof ProductRangeType]
  | EmptyKey;

/**
 * Search Conditions
 */
export interface Conditions {
  access?: number;
  attributeIds?: string[];
  childrenIds?: string[];
  floorPlanRooms?: number[];
  locationIds: string[];
  maxBuildingArea?: number;
  maxLandArea?: number;
  maxPrice?: number;
  minBuildingArea?: number;
  minLandArea?: number;
  minPrice?: number;
  organizationId?: string[];
}

export type PropertyCategory = 'detachedHouse' | 'land';
export type BuildingStatus = 'new' | 'unoccupied' | 'used';

export const ProductSalesDateSeason = {
  BEGIN: { key: 'begin', label: '初旬' },
  EARLY: { key: 'early', label: '上旬' },
  END: { key: 'end', label: '末' },
  LATE: { key: 'late', label: '下旬' },
  MIDDLE: { key: 'middle', label: '中旬' },
} as const;
export type ProductSalesDateSeasonKey =
  (typeof ProductSalesDateSeason)[keyof typeof ProductSalesDateSeason]['key'];

export const ProductLotteryDateSeason = {
  BEGIN: { key: 'begin', label: '初旬' },
  EARLY: { key: 'early', label: '上旬' },
  END: { key: 'end', label: '末' },
  LATE: { key: 'late', label: '下旬' },
  MIDDLE: { key: 'middle', label: '中旬' },
} as const;
export type ProductLotteryDateSeasonKey =
  (typeof ProductLotteryDateSeason)[keyof typeof ProductLotteryDateSeason]['key'];

export const ProductSalesStatus = {
  NEW_BUILDING_CONDO: { key: 'newBuildingCondo', label: '新築分譲' },
  SCHEDULED_SALE: { key: 'scheduledSale', label: '販売予定' },
  SECRET: { key: 'secret', label: '掲載止め' },
  SELLING_STOP: { key: 'sellingStop', label: '売り止め' },
  SOLD_OUT: { key: 'soldOut', label: '完売・成約' },
  USED_CONDO: { key: 'usedCondo', label: '分譲中' },
} as const;
export type ProductSalesStatusKey =
  (typeof ProductSalesStatus)[keyof typeof ProductSalesStatus]['key'];

export const ProductLegalOtherLimitationType = {
  CERTIFICATED: {
    key: '1',
    label:
      '建築基準法43条2項第1号認定済※建築可能な建物は200平米以内の専用住宅に限られる',
  },
  PERMITTED: { key: '2', label: '建築基準法43条2項第2号許可済' },
} as const;
export type ProductLegalOtherLimitationTypeKey =
  (typeof ProductLegalOtherLimitationType)[keyof typeof ProductLegalOtherLimitationType]['key'];

export const ProductEventName = {
  LOCAL_GUIDE: { key: 'localGuide', label: '現地案内会' },
  LOCAL_SALE: { key: 'localSale', label: '現地販売会' },
  LOCAL_TOUR: { key: 'localTour', label: '現地見学会' },
  MODEL_HOUSE: { key: 'modelHouse', label: 'モデルハウス' },
  MODEL_ROOM: { key: 'modelRoom', label: 'モデルルーム' },
  OPEN_HOUSE: { key: 'openHouse', label: 'オープンハウス' },
  OPEN_ROOM: { key: 'openRoom', label: 'オープンルーム' },
} as const;
export type ProductEventNameKey =
  (typeof ProductEventName)[keyof typeof ProductEventName]['key'];

export const ProductEventDateType = {
  BETWEEN: { key: 'between', label: '期間限定' },
  EVERY_HOLIDAY: { key: 'everyHoliday', label: '毎週土日祝' },
  EVERY_WEEKEND: { key: 'everyWeekend', label: '毎週土日' },
  FIXED_DATE: { key: 'fixedDate', label: '日時指定' },
  IN_PROGRESS: { key: 'inProgress', label: '公開中' },
} as const;
export type ProductEventDateTypeKey =
  (typeof ProductEventDateType)[keyof typeof ProductEventDateType]['key'];

export const ProductEventAction = {
  DIRECT_VISIT: { key: '1', label: '直接現地へご来場ください' },
  INQUIRY: { key: '2', label: '事前に必ずお問い合わせください' },
  RESERVATION: { key: '3', label: '事前に必ず予約してください' },
} as const;
export type ProductEventActionKey =
  (typeof ProductEventAction)[keyof typeof ProductEventAction]['key'];

export const ProductVariantPlotMapCategory = {
  PLOT_MAP_ALL: {
    key: '01',
    label: '全体区画図',
  },
} as const;
export type ProductVariantPlotMapCategoryKey =
  (typeof ProductVariantPlotMapCategory)[keyof typeof ProductVariantPlotMapCategory]['key'];

export const ProductVariantFloorPlanCategory = {
  FLOOR_PLAN: {
    key: '02',
    label: '間取り図',
  },
  PLOT_MAP: {
    key: '01',
    label: '区画図',
  },
} as const;
export type ProductVariantFloorPlanCategoryKey =
  (typeof ProductVariantFloorPlanCategory)[keyof typeof ProductVariantFloorPlanCategory]['key'];

export const ProductVariantInteriorCategory = {
  AERIAL: {
    key: '07',
    label: '航空写真',
  },
  BALCONY: {
    key: '26',
    label: 'バルコニー',
  },
  BATHROOM: {
    key: '12',
    label: '浴室',
  },
  BEDROOM: {
    key: '16',
    label: 'リビング以外の居室',
  },
  ENTRANCE: {
    key: '25',
    label: '玄関',
  },
  EXTERIOR: {
    key: '01',
    label: '現地外観写真',
  },
  EXTERIOR_RENDER: {
    key: '04',
    label: '完成予想図(外観)',
  },
  GARDEN: {
    key: '24',
    label: '庭',
  },
  HILL: {
    key: '08',
    label: '高台写真',
  },
  INTERIOR_RENDER: {
    key: '22',
    label: '完成予想図(内観)',
  },
  KITCHEN: {
    key: '14',
    label: 'キッチン',
  },
  LIVING: {
    key: '10',
    label: 'リビング',
  },
  MODEL_HOUSE: {
    key: '28',
    label: 'モデルハウス写真',
  },
  OTHER: {
    key: '29',
    label: 'その他',
  },
  OTHER_EXTERIOR: {
    key: '09',
    label: 'その他現地',
  },
  OTHER_INTERIOR: {
    key: '20',
    label: 'その他内観',
  },
  PARKING: {
    key: '27',
    label: '駐車場',
  },
  ROAD: {
    key: '02',
    label: '前面道路含む現地写真',
  },
  SIMILAR_BATHROOM: {
    key: '13',
    label: '同形状・同仕様写真(浴室）',
  },
  SIMILAR_EXTERIOR: {
    key: '03',
    label: '同形状・同仕様写真(外観)',
  },
  SIMILAR_KITCHEN: {
    key: '15',
    label: '同形状・同仕様写真(キッチン）',
  },
  SIMILAR_LIVING: {
    key: '11',
    label: '同形状・同仕様写真(リビング）',
  },
  SIMILAR_OTHER_INTERIOR: {
    key: '21',
    label: '同形状・同仕様写真(その他内観)',
  },
  STORAGE: {
    key: '19',
    label: '収納',
  },
  STREET: {
    key: '05',
    label: '分譲済街並み写真',
  },
  STREET_RENDER: {
    key: '06',
    label: '街並完成予想図',
  },
  TOILET: {
    key: '17',
    label: 'トイレ',
  },
  VIEW: {
    key: '23',
    label: '住戸からの眺望写真',
  },
  WASHBASIN: {
    key: '18',
    label: '洗面台・洗面所',
  },
} as const;
export type ProductVariantInteriorCategoryKey =
  (typeof ProductVariantInteriorCategory)[keyof typeof ProductVariantInteriorCategory]['key'];

// NOTE: 内観と共通カテゴリー
export const ProductVariantExteriorCategory = {
  ...ProductVariantInteriorCategory,
} as const;
export type ProductVariantExteriorCategoryKey =
  (typeof ProductVariantExteriorCategory)[keyof typeof ProductVariantExteriorCategory]['key'];

export const ProductVariantEquipmentCategory = {
  AIR_CONDITIONING: {
    key: '02',
    label: '冷暖房・空調設備',
  },
  ENERGY: {
    key: '01',
    label: '発電・温水設備',
  },
  ENERGY_LABEL: {
    key: '03',
    label: '省エネ性能ラベル',
  },
  OTHER: {
    key: '05',
    label: 'その他設備',
  },
  SECURITY: {
    key: '04',
    label: '防犯設備',
  },
  STRUCTURE: {
    key: '06',
    label: '構造・工法・仕様',
  },
} as const;
export type ProductVariantEquipmentCategoryKey =
  (typeof ProductVariantEquipmentCategory)[keyof typeof ProductVariantEquipmentCategory]['key'];

export const ProductVariantAroundCategory = {
  ACCESS_MAP_CAR: {
    key: '22',
    label: '交通アクセス図(車)',
  },
  BANK: {
    key: '15',
    label: '銀行',
  },
  CITY_HALL: {
    key: '13',
    label: '役所',
  },
  CONVENIENCE_STORE: {
    key: '08',
    label: 'コンビニ',
  },
  DRUGSTORE: {
    key: '09',
    label: 'ドラッグストア',
  },
  ELEMENTARY_SCHOOL: {
    key: '02',
    label: '小学校',
  },
  GUIDE_MAP: {
    key: '21',
    label: '現地案内図',
  },
  HIGH_SCHOOL: {
    key: '04',
    label: '高校・高専',
  },
  HOME_CENTER: {
    key: '10',
    label: 'ホームセンター',
  },
  HOSPITAL: {
    key: '11',
    label: '病院',
  },
  KINDERGARTEN: {
    key: '05',
    label: '保育園・幼稚園',
  },
  LIBRARY: {
    key: '14',
    label: '図書館',
  },
  MIDDLE_SCHOOL: {
    key: '03',
    label: '中学校',
  },
  OTHER: {
    key: '19',
    label: 'その他',
  },
  PARK: {
    key: '17',
    label: '公園',
  },
  POLICE: {
    key: '16',
    label: '警察署・交番',
  },
  POST_OFFICE: {
    key: '12',
    label: '郵便局',
  },
  ROUTE_MAP: {
    key: '23',
    label: '路線図(電車・バス)',
  },
  SHOPPING_CENTER: {
    key: '06',
    label: 'ショッピングセンター',
  },
  STATION: {
    key: '01',
    label: '駅',
  },
  SUPERMARKET: {
    key: '07',
    label: 'スーパー',
  },
  TOWN: {
    key: '18',
    label: '周辺の街並み',
  },
} as const;
export type ProductVariantAroundCategoryKey =
  (typeof ProductVariantAroundCategory)[keyof typeof ProductVariantAroundCategory]['key'];
export const TransportMeans = {
  BUS: 'bus',
  CAR: 'car',
  WALK: 'walk',
} as const;
export type TransportMeansKey =
  (typeof TransportMeans)[keyof typeof TransportMeans];
