import { login } from '@app/adapter/auth-service';
import {
  getProducts as getProductsNetwork,
  getPromotedProducts as getPromotedProductsNetwork,
  getProduct as getSingleProduct,
  getFavorite,
  getFavorites,
} from '@app/adapter/catalog-service';
import {
  getUserOrders as getUserOrdersNetwork,
  getOrder as getOrderNetwork,
} from '@app/adapter/order-service';
import { getUserReviews as getUserReviewsNetwork } from '@app/adapter/review-service';
import { verifyEmail } from '@app/adapter/user-service';
import { generateFingerPrint } from '@app/domain/fingerprint';
import { CategoryTypeKey } from '@app/types/catalog';

export async function handleLogin(email: string, password: string) {
  const fingerprint = await generateFingerPrint();
  const data = await login(email, password);
  return { fingerprint, ...data };
}

export function getVerifyToken() {
  const token = new URLSearchParams(globalThis.location.search).get('token');
  return token || '';
}
export async function verifyEmailToken(token: string) {
  const data = await verifyEmail(token);
  return data;
}

export async function getProducts(filtering: {
  access?: number;
  attributeIds?: string[];
  category?: CategoryTypeKey;
  expand?: string;
  floorPlanRooms?: number[];
  ids?: string[];
  limit?: number | 10;
  locationIds?: string[];
  maxBuildingArea?: number;
  maxLandArea?: number;
  maxPrice?: number;
  minBuildingArea?: number;
  minLandArea?: number;
  minPrice?: number;
  nextLink?: string;
  orderBy?: string;
  organizationId?: string[];
  productIds?: string[];
  statuses?: string[];
}) {
  return await getProductsNetwork(filtering);
}

export async function getPromotedProducts(filtering: {
  limit?: number | 10;
  nextLink?: string;
  order?: 'createdAt' | string;
}) {
  return await getPromotedProductsNetwork(filtering);
}

export async function getProduct(productId: string) {
  return await getSingleProduct(productId);
}

/**
 * Get order list
 * @param token - auth token
 * @param userId - user id
 * @param options - query parameters
 * @returns
 */
export async function getUserOrders(
  token: string,
  userId: string,
  options?: {
    '@nextLink'?: string;
    isAfterWork?: boolean;
    isBeforeWork?: boolean;
    limit?: number;
    orderBy?: string | 'createdAt desc';
    productId?: string;
    statuses?: string[];
    workResultMonth?: string;
  }
) {
  const fingerprint = await generateFingerPrint();
  return await getUserOrdersNetwork(token, fingerprint, userId, {
    ...options,
    $expand: 'organization,lineItems.product',
  });
}

/**
 * Get an order
 * @param token - auth token
 * @param userId - user id
 * @param id - order id
 * @returns
 */
export async function getOrder(token: string, userId: string, id: string) {
  const fingerprint = await generateFingerPrint();
  return await getOrderNetwork(token, fingerprint, userId, id, {
    $expand: 'organization,lineItems.product',
  });
}

export async function getOneFavorite(
  token: string,
  userId: string,
  productId: string
) {
  const fingerprint = await generateFingerPrint();
  return await getFavorite(token, fingerprint, userId, productId);
}

export async function getUserFavorites(
  token: string,
  userId: string,
  filtering?: {
    categoryIds?: string[];
    dateRange?: {
      end: string;
      start: string;
    };
    keyword?: string;
    limit?: number | 10;
    locationIds?: string[];
    order?: 'createdAt' | string;
    organizationExpand?: boolean;
    productExpand?: boolean;
    productIds?: string[];
    statuses?: string[];
  }
) {
  const fingerprint = await generateFingerPrint();

  return await getFavorites(token, fingerprint, userId, filtering);
}

/**
 * Get user review list
 * @param token - auth token
 * @param userId - user id
 * @param options - query parameters
 * @returns
 */
export async function getUserReviews(
  token: string,
  userId: string,
  options?: {
    '@nextLink'?: string;
    limit?: number;
    orderBy?: string | 'createdAt desc';
  }
) {
  const fingerprint = await generateFingerPrint();
  return await getUserReviewsNetwork(token, fingerprint, userId, {
    ...options,
    $expand: '',
  });
}
