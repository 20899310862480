import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

import { Environment } from '@app/static/constants';

const META_DEFAULT = {
  DESCRIPTION:
    '【プロクラ】住宅展示場、分譲地の情報サイトです。魅力的な住宅情報が多数。',
  KEYWORDS: '住宅展示場,分譲地,見学,プロクラ',
  TITLE: '住宅展示場、分譲地の情報サイト｜プロクラ',
};

const isProEnvironment =
  process.env.REACT_APP_NODE_ENV === Environment.PRODUCTION;

type Props = {
  description?: string;
  isNoIndex?: boolean;
  keywords?: string;
  title?: string;
};

export function HeadBlock(props: Props): ReactElement {
  const { description, keywords, title } = props;

  const isNoIndex = !isProEnvironment || props.isNoIndex;

  return (
    <Helmet>
      <title>{title ?? META_DEFAULT.TITLE}</title>
      <meta
        name="description"
        content={description ?? META_DEFAULT.DESCRIPTION}
      />
      <meta name="keywords" content={keywords ?? META_DEFAULT.KEYWORDS} />
      {isNoIndex && <meta name="robots" content="noindex" />}
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
}

export function getProductsTitle(category?: string, value?: string): string {
  const prefix = value ? `${value}の` : '';
  return category
    ? `${prefix}の${category}住宅展示場、分譲地の一覧｜プロクラ`
    : META_DEFAULT.TITLE;
}

export function getProductsDescription(
  category?: string,
  value?: string
): string {
  const prefix = value ? `${value}の` : '';
  return category
    ? `【プロクラ】${prefix}、${category}住宅展示場、分譲地情報一覧です。魅力的な住宅情報が多数。`
    : META_DEFAULT.DESCRIPTION;
}

export function getProductsKeywords(category?: string, value?: string): string {
  const prefix = value ? `,${value}` : '';
  return category
    ? `${category}'住宅展示場,分譲地${prefix}`
    : META_DEFAULT.KEYWORDS;
}
