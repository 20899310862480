import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { getParentOrganizations } from '@app/adapter/organization-service';
import {
  attributesBySmaverseSelector,
  locationsTreeSelector,
  searchConditionState,
} from '@app/domain/catalog';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { SEARCH_OPTIONS } from '@app/static/constants';
import { imageAssets } from '@app/theme';
import { CategoryType, CategoryTypeKey } from '@app/types/catalog';
import { Organization } from '@app/types/organization';

interface HomeCarouselProps {
  isLoading?: boolean;
  setSearchType: (type: CategoryTypeKey) => void;
}

export function HomeCarousel({
  setSearchType,
  isLoading,
}: HomeCarouselProps): ReactElement {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const { isMobile } = useDeviceType();
  const smaverseAttributes = useRecoilValue(attributesBySmaverseSelector);
  const locations = useRecoilValue(locationsTreeSelector);
  const resetSearchConditionState = useResetRecoilState(searchConditionState);
  const [searchType, setSearchTypeLocal] = useState<CategoryTypeKey>(
    CategoryType.EXHIBITION
  );
  const [location, setLocation] = useState<string>('全て');
  const [isSmaverse, setIsSmaverse] = useState<boolean>(false);
  const [organization, setOrganization] = useState('全て');
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await getParentOrganizations();
      setOrganizations(response.data.value);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  }, []);

  useEffect(() => {
    void fetchOrganizations();
  }, [fetchOrganizations]);

  // NOTE: 下のnavigateを実行している副作用が強制的なルーティングをするためlocation.stateで制御している。
  // 現行仕様は分譲トップへ直接アクセスしても展示場トップへアクセスされるなど、Stateとルーティングの関係がうまく制御できていないよう。
  useEffect(() => {
    if (currentLocation.state?.searchType) {
      setSearchType(currentLocation.state?.searchType);
      setSearchTypeLocal(currentLocation.state?.searchType);
    }
  }, [currentLocation.state, setSearchType]);

  useEffect(() => {
    const params = new URLSearchParams(currentLocation.search);
    if (location && location !== '全て') {
      params.set('locations', location);
    } else {
      params.delete('locations');
    }

    const selectedOrganization = organizations.find(
      (org) => org.id === organization
    );
    if (selectedOrganization) {
      params.set('org', selectedOrganization.id);
    } else {
      params.delete('org');
    }

    if (searchType === CategoryType.PROPERTY) {
      params.delete('attributes');
      params.set('category', CategoryType.PROPERTY);
    } else {
      params.set('category', CategoryType.EXHIBITION);
      if (isSmaverse && smaverseAttributes.length) {
        const smaverseIds = smaverseAttributes.map((attr) => attr.id);
        params.set('attributes', smaverseIds.join(','));
      } else {
        params.delete('attributes');
      }
    }

    navigate({ search: params.toString() }, { replace: true });
  }, [
    location,
    organization,
    searchType,
    organizations,
    navigate,
    currentLocation.search,
    isSmaverse,
    smaverseAttributes,
  ]);

  const handleSearchTypeChange = (event: SelectChangeEvent<string>) => {
    const newSearchType = event.target.value as CategoryTypeKey;
    setIsSmaverse(false);
    setSearchTypeLocal(newSearchType);
    setSearchType(newSearchType);
  };

  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    setLocation(event.target.value);
  };

  const handleOrganizationChange = (event: SelectChangeEvent<string>) => {
    setOrganization(event.target.value as string);
  };

  const handleClickSearch = () => {
    const params = new URLSearchParams(currentLocation.search);

    const url =
      searchType === CategoryType.EXHIBITION
        ? `/exhibitions?${params.toString()}`
        : `/properties?${params.toString()}`;
    resetSearchConditionState();
    navigate(url);
  };

  const ResponsiveFormControl = ({
    children,
  }: {
    children: React.ReactNode;
  }) => (
    <FormControl
      variant={isMobile ? 'standard' : 'outlined'}
      sx={{
        '& .MuiInput-underline:before': {
          borderBottomWidth: '1px',
          borderColor: '#EAEAEF',
        },
        mb: isMobile ? 4 : 0,
        minWidth: 180,
        mx: 1,
        width: '90%',
      }}
    >
      {children}
    </FormControl>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        borderRadius: '16px',
        boxShadow: 3,
        height: isMobile ? '80svh' : '60vh',
        m: 3,
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${imageAssets.homeTop})`,
          backgroundPosition: 'center',
          backgroundSize: '115%',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
          zIndex: 2,
        }}
      />
      <Typography
        variant="h1"
        component="div"
        sx={{
          borderRadius: '8px',
          color: 'white',
          fontWeight: 'bold',
          lineHeight: '48px',
          mb: isMobile ? 4 : 12,
          p: 1,
          zIndex: 3,
        }}
      >
        {searchType === CategoryType.PROPERTY ? '分譲地' : '住宅展示場'}
        {isMobile && <br />}
        見学予約サービス
      </Typography>

      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent="space-between"
        bgcolor="rgba(255, 255, 255, 1)"
        p={2}
        borderRadius="8px"
        width={isMobile ? '90%' : 'auto'}
        maxWidth={isMobile ? '90%' : '1016px'}
        sx={{ boxShadow: 3, zIndex: 3 }}
      >
        <ResponsiveFormControl>
          <Select
            value={searchType}
            onChange={handleSearchTypeChange}
            displayEmpty
            IconComponent={ExpandMoreIcon}
            renderValue={(selected) => {
              if (!selected) {
                return <b>住宅展示場を探す</b>;
              }
              return (
                <b>
                  {SEARCH_OPTIONS.find((option) => option.value === selected)
                    ?.label || ''}
                </b>
              );
            }}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              textAlign: 'left',
            }}
          >
            {SEARCH_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </ResponsiveFormControl>

        <ResponsiveFormControl>
          <InputLabel shrink={!!location}>エリア</InputLabel>
          <Select
            value={location}
            onChange={handleLocationChange}
            displayEmpty
            IconComponent={ExpandMoreIcon}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              textAlign: 'left',
            }}
          >
            <MenuItem value="全て">全て</MenuItem>
            {locations.flatMap((region) =>
              region.children
                ? region.children.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))
                : []
            )}
          </Select>
        </ResponsiveFormControl>

        <ResponsiveFormControl>
          <InputLabel shrink={!!organization}>企業</InputLabel>
          <Select
            value={organization}
            onChange={handleOrganizationChange}
            displayEmpty
            IconComponent={ExpandMoreIcon}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              textAlign: 'left',
            }}
          >
            <MenuItem value="全て">全て</MenuItem>
            {organizations.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </ResponsiveFormControl>

        {searchType === CategoryType.EXHIBITION && (
          <FormControlLabel
            disabled={!smaverseAttributes.length || isLoading}
            control={
              <Checkbox
                checked={isSmaverse}
                onChange={() => setIsSmaverse(!isSmaverse)}
              />
            }
            label="スマバース体験のみ"
            sx={{ flexShrink: 0, userSelect: 'none' }}
          />
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleClickSearch}
          sx={{ minWidth: 150, mx: 2, width: '90%' }}
        >
          検索する
        </Button>
      </Box>
    </Box>
  );
}
