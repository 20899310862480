import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { DefaultLayout } from '@app/components/Layout/DefaultLayout';
import { SimpleLayout } from '@app/components/Layout/SimpleLayout';
import { Notification } from '@app/components/Shared/Notification';
import { ScrollToTop } from '@app/hooks/useScroll';
import { CompletedPage as CompanyOrderCompleted } from '@app/views/Company/CompletedPage';
import { Index as CompanyInquiry } from '@app/views/Company/Index';
import { Preview as CompanyPreview } from '@app/views/Company/Preview';
import { Register as CompanyOrderRegister } from '@app/views/Company/Register';
import { ErrorFound } from '@app/views/Error';
import { Home } from '@app/views/Home';
import { LineCallback } from '@app/views/LineCallback';
import { Login } from '@app/views/Login';
import { LoginCallback } from '@app/views/LoginCallback';
import { MockLine } from '@app/views/MockLine';
import { NotFound } from '@app/views/NotFound';
import { ExhibitionCompletedPage as OrderExhibitionCompleted } from '@app/views/Order/ExhibitionCompletedPage';
import { ExhibitionRegister as OrderRegisterExhibition } from '@app/views/Order/ExhibitionRegister';
import { PropertyCompletedPage as OrderPropertyCompleted } from '@app/views/Order/PropertyCompletedPage';
import { RegisterProperty as OrderRegisterProperty } from '@app/views/Order/RegisterProperty';
import { ChangePassword } from '@app/views/PasswordReset/ChangePassword';
import { Complete } from '@app/views/PasswordReset/Complete';
import { EmailSent } from '@app/views/PasswordReset/EmailSent';
import { SendResetPasswordEmail } from '@app/views/PasswordReset/SendResetPasswordEmail';
import { Index as ExhibitionList } from '@app/views/Product/Exhibitions/Index';
import { ExhibitionPreview } from '@app/views/Product/Exhibitions/Preview';
import { Show as ExhibitionProduct } from '@app/views/Product/Exhibitions/Show';
import { Index as PropertyList } from '@app/views/Product/Properties/Index';
import { PropertyPreview } from '@app/views/Product/Properties/Preview';
import { Show as PropertyProduct } from '@app/views/Product/Properties/Show';
import { ProtectedRoute } from '@app/views/ProtectedRoute';
import { Search } from '@app/views/Search';
import { Setting } from '@app/views/Setting';
import { ChangeEmail } from '@app/views/User/ChangeEmail';
import { EditConfirm as UserEditConfirm } from '@app/views/User/EditConfirm';
import { Index as Account } from '@app/views/User/Index';
import { Register as UserRegister } from '@app/views/User/Register';
import { RegistrationConfirmation } from '@app/views/User/RegistrationConfirmation';
import { WaitingForEmailVerify } from '@app/views/WaitingForEmailVerify';

export function App() {
  useEffect(() => {
    const { REACT_APP_GA_TAG_MANAGER_ID } = process.env;
    if (!REACT_APP_GA_TAG_MANAGER_ID) return;
    const tagManagerArgs = {
      gtmId: REACT_APP_GA_TAG_MANAGER_ID,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route element={<SimpleLayout />}>
          <Route path="/register" element={<UserRegister />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/register/complete"
            element={<WaitingForEmailVerify />}
          />
        </Route>
        <Route element={<DefaultLayout showAccount={false} />}>
          <Route
            path="/profile/create"
            element={
              <ProtectedRoute>
                <RegistrationConfirmation />
              </ProtectedRoute>
            }
          />
          <Route path="/password">
            <Route path="reset">
              <Route index element={<SendResetPasswordEmail />} />
              <Route path="email-sent" element={<EmailSent />} />
              <Route path="verify" element={<ChangePassword />} />
              <Route path="complete" element={<Complete />} />
            </Route>
          </Route>
        </Route>

        <Route element={<DefaultLayout />}>
          <Route index element={<Home />} />
          {/* 住宅展示場 */}
          <Route path="/exhibitions">
            <Route index element={<ExhibitionList />} />
            <Route path=":productId" element={<ExhibitionProduct />} />
            <Route
              path=":productId/inquiry/create"
              element={<OrderRegisterExhibition />}
            />
            <Route
              path=":productId/inquiry/create/complete"
              element={<OrderExhibitionCompleted />}
            />
          </Route>
          {/* Company */}
          <Route path="/companies">
            <Route
              path=":organizationId/inquiry/create"
              element={<CompanyOrderRegister />}
            />
            <Route
              path=":organizationId/inquiry/create/complete"
              element={<CompanyOrderCompleted />}
            />
            <Route path=":organizationId" element={<CompanyInquiry />} />
          </Route>

          {/* 分譲 */}
          <Route path="/properties">
            <Route index element={<PropertyList />} />
            <Route path=":productId" element={<PropertyProduct />} />
            <Route
              path=":productId/inquiry/create"
              element={<OrderRegisterProperty />}
            />
            <Route
              path=":productId/inquiry/create/complete"
              element={<OrderPropertyCompleted />}
            />
          </Route>

          <Route path="/login-callback/:provider" element={<LoginCallback />} />
          <Route
            path="/setting"
            element={
              <ProtectedRoute>
                <Setting />
              </ProtectedRoute>
            }
          />
          <Route path="/exhibitions/search" element={<Search />} />
          <Route path="/properties/search" element={<Search />} />
          <Route path="/account" element={<Account />} />
          <Route path="/profile" element={<Account />} />
          <Route path="/profile/edit" element={<Account />} />
          <Route
            path="/profile/edit-confirm"
            element={
              <ProtectedRoute>
                <UserEditConfirm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/change-email"
            element={
              <ProtectedRoute>
                <ChangeEmail />
              </ProtectedRoute>
            }
          />

          <Route path="/home" element={<Home />} />
          <Route path="/notifications" element={<Notification />} />

          {/* NOTE: Temporarily added */}
          <Route path="/mock-line" element={<MockLine />} />
          <Route path="/line/callback" element={<LineCallback />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/error" element={<ErrorFound />} />
        </Route>
        <Route
          path="/exhibitions-preview/:productId"
          element={<ExhibitionPreview />}
        />
        <Route
          path="/properties-preview/:productId"
          element={<PropertyPreview />}
        />
        <Route path="/companies-preview/:id" element={<CompanyPreview />} />
      </Routes>
    </Router>
  );
}
