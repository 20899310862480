import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { ImageCarousel } from '@app/components/Product/CarouselItem';
import { StrengthsSection } from '@app/components/Product/StrengthsSection';
import { Tag } from '@app/components/Shared/Tag';
import { attributesBySmaverseSelector } from '@app/domain/catalog';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { Product } from '@app/types/catalog';
import {
  checkSmaverseSupport,
  getBuildingStatus,
  getPropertyCategory,
} from '@app/utils/catalog';

interface ProductDetailsProps {
  product: Product;
}

export function ProductDetails({ product }: ProductDetailsProps) {
  const theme = useTheme();
  const { isMobile } = useDeviceType();
  const smaverseAttributes = useRecoilValue(attributesBySmaverseSelector);
  const isSmaverse = useMemo(() => {
    return checkSmaverseSupport(product.attributes || [], smaverseAttributes);
  }, [product.attributes, smaverseAttributes]);

  return (
    <Stack spacing={3} sx={{ width: '100%' }}>
      <Typography variant="h2" fontWeight={600}>
        {product.name}
      </Typography>
      <Box display="flex" gap={1}>
        <Tag
          text={getPropertyCategory(product.customFields.propertyCategory)}
        />
        <Tag text={getBuildingStatus(product.customFields.buildingStatus)} />
      </Box>
      {typeof product.organization === 'object' &&
        product.organization !== null && (
          <Typography
            variant="body1"
            color={theme.palette.grey[500]}
            sx={{ mt: '5px !important' }}
          >
            {product.addressLine1 + product.addressLine2}
            {!!product.customFields.addressLine3HiddenFlag &&
              product.addressLine3}
          </Typography>
        )}

      <ImageCarousel
        isSmaverse={isSmaverse}
        images={product.images.map((img) => img.url)}
        displayMode="largeImage"
        carouselWidth={isMobile ? 'calc(100vw - 64px)' : '980px'}
      />

      <StrengthsSection
        features={product.features}
        strengthsTitle={product.additionalInformation}
        strengthsDescription={product.description}
        showReadMore={false}
      />
    </Stack>
  );
}
