import { Theme } from '@emotion/react';
import { SxProps, Typography } from '@mui/material';

import smaverseIcon from '@app/static/images/smaverseIcon.svg';
import { theme } from '@app/theme';

export function SmaverseTag({ sx }: { sx?: SxProps<Theme> }) {
  return (
    <Typography
      variant="body2"
      color={theme.palette.primary.main}
      sx={{
        alignItems: 'center',
        backgroundColor: 'neutral.greyLight',
        borderRadius: '8px',
        display: 'flex',
        fontWeight: 'bold',
        padding: '2px 6px',
        position: 'absolute',
        ...sx,
      }}
    >
      <img
        src={smaverseIcon}
        width="16"
        height="16"
        alt="smaverse"
        style={{ marginRight: '3px' }}
      />
      スマバース体験可
    </Typography>
  );
}
