import { Divider, Stack, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ImageCarousel } from '@app/components/Company/CarouselItem';
import { CompanyInfo } from '@app/components/Company/CompanyInfo';
import { CompanyInfoSp } from '@app/components/Company/CompanyInfoSp';
import { FeaturesSection } from '@app/components/Company/FeaturesSection';
import { OrganizationStrengths } from '@app/components/Company/OrganizationStrengths';
import { TextPretty } from '@app/components/Shared/TextPretty';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { Organization } from '@app/types/organization';

interface OrganizationDetailProps {
  organization: Organization;
}

export function OrganizationDetail({ organization }: OrganizationDetailProps) {
  const theme = useTheme();
  const { isMobile } = useDeviceType();

  const carouselImages = useMemo(() => {
    return organization?.customFields?.mainImages?.length
      ? (organization.customFields.mainImages.map((img) => img.url) as string[])
      : [];
  }, [organization?.customFields?.mainImages]);

  return (
    <Stack
      spacing={3}
      divider={<Divider />}
      sx={{ px: { md: 8, xs: 2 }, width: '100%' }}
    >
      <div>
        <Typography variant="h2" fontWeight="bold">
          {organization.name}
        </Typography>
        <Typography
          variant="body1"
          color={theme.palette.grey[500]}
          sx={{ mt: '5px !important' }}
        >
          {organization.customFields?.subText}
        </Typography>
        <ImageCarousel images={carouselImages} displayMode="largeImage" />
      </div>
      <Stack direction="row">
        <Typography mr={3}>施工エリア</Typography>
        <Link to={'#'} style={{ color: '#326EE6', textDecoration: 'none' }}>
          <TextPretty
            text={organization.customFields?.constructionArea}
            formType="lightBase"
          />
        </Link>
      </Stack>
      <FeaturesSection
        features={organization.customFields?.features || []}
        mainCatch={organization.customFields?.mainCatch || ''}
        subCatch={organization.customFields?.subCatch || ''}
        showReadMore={false}
      />
      {!!organization.customFields?.strengths?.length && (
        <OrganizationStrengths data={organization.customFields.strengths} />
      )}
      {isMobile ? (
        <CompanyInfoSp organization={organization} />
      ) : (
        <CompanyInfo organization={organization} />
      )}
    </Stack>
  );
}
